<template>
    <div class="info">
      <top-bar :title="'新增房屋'" :left="true"></top-bar>

      <van-popup v-model="CommunityShow"  position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.CommunityList" value-key="label" @confirm="CommunityConfirm"  @cancel="CommunityShow = false"/>
      </van-popup>

      <!-- 所在小区 -->
      <van-popup v-model="subareaShow" position="bottom" >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.subareaList.length < 1 ">没有获取到小区信息,请先选择社区</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.subareaList" value-key="label"
                    @confirm="subareaConfirm" @cancel="subareaShow = false"/>
      </van-popup>

      <van-popup v-model="BuildingShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到楼幢信息,请先选择小区</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.BuildingList" value-key="label"  @confirm="BuildingConfirm"  @cancel="BuildingShow = false"/>
      </van-popup>

      <van-popup v-model="unitShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.unitList.length < 1 ">没有获取到单元信息,请先选择楼幢</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.unitList" value-key="label"  @confirm="unitConfirm"  @cancel="unitShow = false"/>
      </van-popup>


      <van-popup v-model="attrShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.attrList" value-key="label"  @confirm="attrConfirm"  @cancel="attrShow = false"/>
      </van-popup>

      <van-popup v-model="doorModelShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.doorModelList" value-key="label"  @confirm="doorModelConfirm"  @cancel="doorModelShow = false"/>
      </van-popup>
      <van-popup v-model="manyHomeownersShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.manyHomeownersList" value-key="label"  @confirm="manyHomeownersConfirm"  @cancel="manyHomeownersShow = false"/>
      </van-popup>

      <van-popup v-model="codeResultShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.codeList" value-key="addr"
                    item-height="88px" class="codePicker" @confirm="codeConfirm"  @cancel="codeCancel"/>
      </van-popup>

      <div v-show="!gridShow">
        <van-cell-group>
          <van-cell>
            <template #title>
              <div class="blue-box"></div>
              <span class="custom-title">新增房屋</span>
              <div v-if="!!ranDomData.checkId" style="color: #E9564B;margin-top: 2px;">{{ranDomData.cause}}</div>
            </template>
          </van-cell>
          <van-cell title="所在社区" :value="selectMsg.Community" @click="CommunityShow = true" is-link :value-class="{'value-common':selectMsg.Community=='请选择'}"/>
          <van-cell title="所在小区" :value="selectMsg.subareaStr" @click="subareaShow = true" is-link :value-class="{'value-common':selectMsg.subareaStr=='请选择'}" />
          <van-cell title="所在楼幢" :value="selectMsg.Building"  @click="BuildingShow = true"  is-link :value-class="{'value-common':selectMsg.Building=='请选择'}"/>
          <van-cell title="所在单元" v-if="unitVisible == true" :value="selectMsg.unit" @click="unitShow = true" is-link :value-class="{'value-common':selectMsg.unit=='请选择'}"/>
          <van-cell><van-field clearable clear-trigger="always" v-model="submitData.name" label="房屋名" placeholder="请输入" input-align="right" @blur="searchCode"/></van-cell>
          <van-cell><van-field clearable clear-trigger="always" v-model="submitData.areas" label="房屋面积 m²" placeholder="请输入" input-align="right"/></van-cell>
          <van-cell><van-field clearable clear-trigger="always" v-model="submitData.floor" label="所在楼层" placeholder="请输入" input-align="right"/></van-cell>
          <van-cell title="户型" :value="selectMsg.doorModel" @click="doorModelShow = true" is-link :value-class="{'value-common':selectMsg.doorModel=='请选择'}"/>
          <van-cell title="所属网格" :value="selectMsg.gridName" @click="!gridDisabled ? (gridShow = true) : false" :readonly="gridDisabled"
                    :value-class="{'value-common':selectMsg.gridName=='请选择'}" is-link/>
        </van-cell-group>

        <van-cell-group class="info-second">
          <van-cell>
            <template #title>
              <div class="blue-box"></div>
              <span class="custom-title">附属信息</span>
              <!-- <div style="color:#387FF5;float:right" @click="infoVisible=!infoVisible"><van-icon :name="infoVisible?'arrow-up':'arrow-down'"></van-icon></div> -->
            </template>
          </van-cell>
          <van-cell title="房屋属性" :value="selectMsg.useType" @click="attrShow = true" is-link :value-class="{'value-common':selectMsg.useType=='请选择'}"/>
          <van-cell><van-field clearable clear-trigger="always" v-model="submitData.houseCard" label="不动产权证号" placeholder="请输入" input-align="right"/></van-cell>
          <van-cell><van-field clearable clear-trigger="always" v-model="submitData.collectiveOwnership" label="共有情况" placeholder="请输入" input-align="right"/></van-cell>
          <van-cell><van-field clearable clear-trigger="always" v-model="submitData.rentalRoomNum" label="出租间数" placeholder="请输入" input-align="right"/></van-cell>
          <van-cell><van-field clearable clear-trigger="always" v-model="submitData.residentsLimit" label="限住人数" placeholder="请输入" input-align="right"/></van-cell>
          <van-cell title="是否分户" :value="selectMsg.manyHomeowners" @click="manyHomeownersShow = true" is-link :value-class="{'value-common':selectMsg.manyHomeowners=='请选择'}"/>
          <!-- <van-field clearable clear-trigger="always" v-model="submitData.code" label="统一地址编码" placeholder="请输入" input-align="right"/> -->
          <van-cell title="统一地址编码"  :is-link="codeResultVisible&&!noResult" @click="codeResultVisible&&!noResult?codeResultShow=true:''">
            <template slot="default">
              <span v-if="submitData.code&&codeResultVisible">
                <van-icon name="checked" color="#23D87B"/>{{submitData.code}}
              </span>
              <span v-if="!submitData.code&&codeResultVisible" style="display:flex;align-items:center;justify-content:right">
                <template v-if="noResult"><span style="margin-right:10px"><van-icon name="clear" color="#ED4747"/>匹配失败</span></template>
                <van-button type="info" color="#ED4747" size="mini" round @click.stop="relief">发起救济</van-button>
                <template v-if="!noResult"><span style="margin-left:10px" class="value-common">请选择</span></template>
              </span>
            </template>
          </van-cell>
          <van-field clearable clear-trigger="always" v-model="submitData.remark" placeholder="备注" type="textarea" autosize input-align="left" class="left"/>
        </van-cell-group>

        <van-row class="btns">
          <van-col :span="24">
            <van-button type="info" size="large" round @click="submit(0)">确认添加</van-button>
          </van-col>
        </van-row>
      </div>

    <grid-select v-show="gridShow" :list="selectData.gridList" @selectLabel="selectGrid" :isArray="1" ref="gridSelect"></grid-select>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {isNumber} from '@/utils/validate'
import {getbelongSubArea, getDictTree,getVirtualDict, getBelongCommunity, getMiniGrid} from '@/utils/common'
import GridSelect from './grid-select.vue'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      unitVisible: true,
      detailData: {},
      CommunityShow: false,
      subareaShow: false,
      BuildingShow: false,
      unitShow: false,
      attrShow: false,
      doorModelShow: false,
      manyHomeownersShow: false,
      codeResultShow: false,
      noResult: false,
      codeResultVisible: false,
      gridShow: false,
      gridDisabled: false,
      ranDomData: {
        dataType: '',
        cause: '',
        // 督查id
        checkId: 0,
      },
      selectMsg: {
        Community: '请选择',
        subareaStr: '请选择',
        Building: '请选择',
        unit: '请选择',
        useType: '请选择',
        doorModel: '请选择',
        manyHomeowners: '请选择',
        description: '',
        gridName: '请选择'
      },
      submitData: {
        building:'',
        content:'',
        orgId:0,
        subarea:'',
        useType:'',
        areas: '',
        name: '',
        floor: '',
        unit:'',
        userId:'',
        doorModel: '',
        manyHomeowners: '',
        houseCard: '',
        collectiveOwnership: '',
        rentalRoomNum: '',
        residentsLimit: '',
        code: '',
        remark: '',
        gridId: 0,
        miniGridId: 0,
        buildGrid: 0,
        buildMiniGridId: 0,
        unitGrid: 0
      },
      selectData: {
        CommunityList: [],
        BuildingList: [],
        unitList: [],
        attrList: [],
        doorModelList: [],
        manyHomeownersList: [{value:0,label:'否'},{value:1,label:'是'}],
        codeList: [],
        subareaList: [],
        gridList: []
      },
    }
  },
  computed:{...mapState(['ProblemId'])},
  components :{
    topBar,
    GridSelect
  },
  methods: {
    init () {
      let that = this
      getVirtualDict("doorModel", function (doorModelList) {
        that.selectData.doorModelList = doorModelList
      })
      getMiniGrid({community:this.$orgId || sessionStorage.getItem('orgId')}, function (list) {
        that.selectData.gridList = list
      })
      // this.$http({
      //   url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
      //   method: 'post',
      //   params: this.$http.adornParams({
      //     communityId: this.$orgId
      //   },false)
      // }).then(({data})=> {
      //     if (data && data.code == 0) {
      //       this.selectData.CommunityList = data.subAreaList
      //     } else {
      //     this.$toast.fail(data.msg);
      //     }
      // })
      getBelongCommunity(this.submitData.orgId, -1, (communityList) => {
        this.selectData.CommunityList = communityList
      })
      // 获取属性列表
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual/useType'),
        method: 'get'
      }).then(({data})=> {
        if (data && data.code == 0) {
          this.selectData.attrList = data.useTypes
        }
      },err=> {this.$toast.console.error({message:err})})
    },
    getGrids() {
      getMiniGrid({community: this.$orgId || sessionStorage.getItem('orgId')}, function (list) {
        if (that.submitData.buildMiniGridId) {
          that.selectData.gridList = list.filter(item => {
            return item.value == that.submitData.buildGrid
          }).map(item => {
            that.$set(item, 'disabled', true)
          })
          that.selectData.gridList[0].filter(item => {
            return item.value == that.submitData.buildMiniGridId
          })
        } else if (that.submitData.unitGrid) {
          that.selectData.gridList = list.filter(item => {
            return item.value == that.submitData.buildGrid
          }).map(item => {
            that.$set(item, 'disabled', true)
          })
          that.selectData.gridList[0].filter(item => {
            return item.value == that.submitData.unitGrid
          })
        } else if (that.submitData.buildGrid) {
          that.selectData.gridList = list.filter(item => {
            return item.value == that.submitData.buildGrid
          })
          that.$set(that.selectData.gridList[0], 'disabled', true)
        } else {
          that.selectData.gridList = []
        }
      })
    },
    checkUpdate () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/dataCalibration/calibration/update`),
        method: 'post',
        data: this.$http.adornData({
          id: this.ranDomData.checkId
        }, false)
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.code == 0) {
            this.$toast.success({
              message: '成功',
              duration: 1500,
              onClose: () => {
                this.$router.go(-1)
              }
            })
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    submit (forceCalibration) {
      if (this.selectMsg.Community === '请选择') {return this.$toast.fail('失败:请先选择小区');}
      if (this.selectMsg.Building === '请选择') {return this.$toast.fail('失败:请先选择楼幢');}
      if (this.submitData.building !== '15098') {
        if ( this.selectMsg.unit === '请选择') {return this.$toast.fail('失败:请先选择单元');}
      }
      if ( this.submitData.areas === '') {return this.$toast.fail('失败:请先输入房屋面积');}
      if ( this.submitData.name === '') {return this.$toast.fail('失败:请先输入房屋名');}
      if ( this.submitData.floor === '') {return this.$toast.fail('失败:请先输入所在楼层');}
      if ( this.selectMsg.useType === '请选择') {return this.$toast.fail('失败:请先选择房屋属性');}
      if (this.selectMsg.remark === '请选择') {return this.$toast.fail('失败:请先输入备注描述');}
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/save'),
        method: 'post',
        data : this.$http.adornParams({
          areas: this.submitData.areas,
          buildingId: this.submitData.building,
          floor: this.submitData.floor,
          id: 0,
          name: this.submitData.name,
          remark: this.submitData.remark,
          community: this.submitData.community,
          subarea: this.submitData.subarea + '',
          unitId: this.submitData.unit,
          useType: this.submitData.useType + '',
          doorModel: this.submitData.doorModel,
          manyHomeowners: this.submitData.manyHomeowners,
          houseCard: this.submitData.houseCard,
          collectiveOwnership: this.submitData.collectiveOwnership,
          rentalRoomNum: this.submitData.rentalRoomNum,
          residentsLimit: this.submitData.residentsLimit,
          code: this.submitData.code,
          gridId: this.submitData.gridId || 0,
          miniGridId: this.submitData.miniGridId ? this.submitData.miniGridId : 0,
          forceCalibration: forceCalibration,
          calibrationType: this.ranDomData.dataType
        })
      }).then(({data})=> {
          if (data && data.code == 0) {
            if (!!this.ranDomData.checkId) {
              this.checkUpdate()
            } else {
              this.$toast.success({
                duration: 1500,
                forbidClick: true,
                message: '新增成功',
                onClose: () => {
                  this.$router.go(-1)
                }
              });
            }
          } else if (data.code == 100) {
            this.$dialog.confirm({
              message: `${data.msg}，是否确认信息正确?`
            }).then(() => {
              this.submit(1)
            }).catch(() => {
              // this.$router.go(-1)
            })
          } else {
            this.$toast.fail(data.msg);
          }
      })
    },
    // 社区选择
    CommunityConfirm (value) {
      if (value) {
        this.selectMsg.Community = value.label
        this.submitData.community = value.value
        this.selectMsg.Building = '请选择'
        this.selectMsg.unit = '请选择'
        this.submitData.unit = ''
        this.selectData.unitList = []
        this.selectData.BuildingList = []
        getBelongCommunity(value.value, 0, (communityList) => {
          this.selectData.subareaList = communityList
        })
      }
      this.CommunityShow = false
    },
    // 所在小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.subareaStr = value.label
        this.submitData.subarea = value.value
        getBelongCommunity(value.value, 1, (communityList) => {
          this.selectData.BuildingList = communityList
        })
      }
      this.subareaShow = false
    },
    // 楼栋选择
    BuildingConfirm (value) {
      if (value.parameters.divideHouse == '0') {
        return this.$toast.fail('该楼不分房，请重选选择')
      }
      if (value) {
        this.getBuildingInfo(value.value)
        this.selectMsg.Building = value.label
        this.submitData.building = value.value
        this.selectMsg.unit = '请选择'
        this.submitData.unit = ''
        this.selectData.houseList = []
        this.selectMsg.house = '请选择'
        this.submitData.house = ''
        this.selectMsg.gridName = '请选择'
        this.submitData.gridId = ''
        if (value.parameters.divideUnit == '0') {
          this.unitVisible = false
          this.getGrids()
        } else {
          this.unitVisible = true
          getBelongCommunity(value.value, 2, (communityList) => {
            this.selectData.unitList = communityList
          })
        }
        if (value.value && this.submitData.unit !== '') {
          this.getUnitInfo(value.value)
        }
      }
      this.BuildingShow = false
    },
    getBuildingInfo(buildingId) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/info/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: buildingId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.submitData.buildGrid = data.info.gridId
          this.submitData.buildMiniGridId = data.info.miniGridId
          if (this.submitData.buildMiniGridId) {
            this.gridDisabled = true
            this.selectMsg.gridName = data.info.gridName + '(' + data.info.miniGridName + ')'
          } else {
            this.gridDisabled = false
          }
        }
      })
    },
    // 单元选择
    unitConfirm (value) {
      if(value) {
      this.selectMsg.unit = value.label
      this.submitData.unit = value.value
      this.selectMsg.house = '请选择'
      this.submitData.house = ''
      this.selectMsg.gridName = '请选择'
      this.submitData.gridId = ''
      if (value.value && this.submitData.building !== '') {
        this.getUnitInfo(value.value)
      }
      }
      this.unitShow = false
    },
    getUnitInfo (unitId) {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/unit/info`),
        method: 'post',
        params: this.$http.adornParams({
          id: unitId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.submitData.unitGrid = data.info.miniGridId
          this.getGrids()
          if (data.info.miniGridId) {
            this.gridDisabled = true
            this.selectMsg.gridName = data.info.gridName + '(' + data.info.miniGridName + ')'
          } else {
            this.gridDisabled = false
          }
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    // 网格选择
    selectGrid (e) {
      this.submitData.gridId = 0
      this.submitData.miniGridId = 0
      const {selectList, labelCondition, depth} = e
      this.selectMsg.gridName = labelCondition[0].label;
      this.submitData.gridId = e.labelCondition[0].fatherValue || parseInt(selectList)
      this.submitData.miniGridId = e.labelCondition[0].fatherValue ? parseInt(selectList) : 0
      this.gridShow = false
    },
    codeCancel () {
      this.codeResultShow = false
      this.submitData.code = ''
    },
    searchCode (e) {
      let value = e.currentTarget.value
      if (value && isNumber(value) == true) {
        if (value.length == 3) {
          this.submitData.floor = value.substring(0, 1)
        } else if (value.length == 4) {
          this.submitData.floor = value.substring(0, 2)
        } else {
          this.submitData.floor = ''
        }
      } else {
        this.submitData.floor = ''
      }
      if (this.submitData.subarea && this.submitData.building && (this.unitVisible ? this.submitData.unit : true) && this.submitData.name) {
        this.$http({
          url: this.$http.adornUrl('/wxapp/spt/addressCode/addressSearch'),
          method: 'post',
          params: this.$http.adornParams({
            addr: this.selectMsg.Community + this.selectMsg.Building + (this.unitVisible ? this.selectMsg.unit : '') + this.submitData.name
          })
        }).then(({data})=> {
          if (data && data.code == 0) {
            this.selectData.codeList = data.searchAddrList
            this.noResult = false
            this.codeResultVisible = true
          } else if (data.code == 500) {
            this.noResult = true
            this.codeResultVisible = true
          } else {
            this.$toast.fail(data.msg);
          }
        })
      }
    },
    codeConfirm (value) {
      this.submitData.code = value.code
      this.codeResultShow = false
    },
    // 属性选择
    attrConfirm (value) {
      this.selectMsg.useType = value.label
      this.submitData.useType = value.value
      this.attrShow = false
    },
    doorModelConfirm (value) {
      this.selectMsg.doorModel = value.label
      this.submitData.doorModel = value.value
      this.doorModelShow = false
    },
    manyHomeownersConfirm (value) {
      this.selectMsg.manyHomeowners = value.label
      this.submitData.manyHomeowners = value.value
      this.manyHomeownersShow = false
    },
    onCancel() {
    },
    relief () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/spt/addressRelief/addressRelief'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId || sessionStorage.getItem('orgId'),
          buildingId: this.submitData.building,
          unitId: this.submitData.unit || '',
          houseId: ''
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.selectData.codeList = data.searchAddrList
          this.noResult = false
          this.codeResultVisible = true
        } else {
          this.$toast.fail(data.msg);
        }
      })
    }
  },
  created () {
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId || sessionStorage.getItem('orgId')
    this.submitData.userId = this.$globalData.userInfo.userId
    let options = this.$route.query
    if (this.$route.query.adrrType) {
      this.houseId = parseInt(options.id)
      this.isEdit = true
      this.ranDomData.dataType = parseInt(options.adrrType)
      this.ranDomData.cause = options.cause
      this.ranDomData.checkId = parseInt(options.checkId)
    }
    this.$nextTick(() => {
      this.init()
    })
  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style scoped>
.codePicker >>> .van-ellipsis {
  text-align: center;
  overflow: visible;
  white-space: normal;
  line-height: 60px;
}
.info >>> .van-radio-group {
  float: unset !important;
}
</style>
